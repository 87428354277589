import HabeasDataServicesImpl from "./habeas_data_services_impl";

export default {
  getHabeasData: async () => {
    return HabeasDataServicesImpl.getHabeasData();
  },
  getHabeasDataBySearching: async (term) => {
    return HabeasDataServicesImpl.getHabeasDataBySearching(term);
  },
  // getReport: async (document_id) => {
  //   return AnnualReportServicesImpl.getReport(document_id);
  // },
  // manageReport: async (document_id, data) => {
  //   return AnnualReportServicesImpl.manageReport(document_id, data);
  // },
  // updateReport: async (document_id, data) => {
  //   return AnnualReportServicesImpl.updateReport(document_id, data);
  // },
};
